









import { Component, Vue } from "vue-property-decorator"

@Component({
  metaInfo: {
    title: "Discord4J - Oops! You shouldn't be here.",
  },
})
export default class NotFound extends Vue {}
